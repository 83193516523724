import styles from './FilterInput.module.scss'
import { Col, Form, Row } from 'react-bootstrap'

interface Props {
  name: string
  label: JSX.Element
  Selector: JSX.Element
  setOpen: () => void
  isOpen: boolean
  colSize?: number
}

const FilterInput = ({ name, label, Selector, setOpen, isOpen, colSize = 4 }: Props) => {
  return (
    <>
      <Form.Group as={Col} lg={colSize} data-name={name} className="form-group filter-input">
        <div className={`${styles.fakeSelect} ${isOpen && styles.active}`} onClick={setOpen}>
          <div className={styles.label}>{label}</div>
        </div>
      </Form.Group>
      <Form.Group as={Col} xs="12" className={`mb-2 order-lg-last ${styles.filterOptionsWrap} ${isOpen && styles.active}`}>
        <Row>{Selector}</Row>
      </Form.Group>
    </>
  )
}

export default FilterInput
