import styles from './ImageBlock.module.scss'
import cloudinaryUrlGenerator from '@/integrations/cloudinary/cloudinaryUrlGenerator'
import cmsImageBlockObject from '@/entities/cmsImageBlock/interface'
import { Parallax } from 'react-parallax'
import { fill } from '@cloudinary/url-gen/actions/resize'

const ImageBlock = ({ image, text }: cmsImageBlockObject) => {
  const cld = cloudinaryUrlGenerator
  const myImage = cld.image(image.publicId)
  const cropSettings = fill()
  // cropSettings.width(1920)
  cropSettings.height(450)
  cropSettings.aspectRatio(4.3)
  cropSettings.gravity('auto')
  myImage.resize(cropSettings).format('auto').quality('auto')

  return (
    <>
      <div className={`${styles.root}`}>
        <Parallax bgImage={myImage.toURL()} strength={150}>
          <div style={{ height: '30vh', minHeight: '300px', maxHeight: '350px', lineHeight: 1 }}>
            <div className={styles.text}>{text}</div>
          </div>
        </Parallax>
      </div>
    </>
  )
}

export default ImageBlock
