import styles from './AccordionBlock.module.scss'
import Accordion from '@/components/elements/Accordion'

interface AccordionItem {
  title: string
  content: string
}

interface Props {
  items: Array<AccordionItem>
}

const AccordionBlock = ({ items }: Props) => {
  return <Accordion items={items} className={styles.root} />
}

export default AccordionBlock
