import styles from './WysiwygBlock.module.scss'
// import SanitizedHTML from 'react-sanitized-html'

interface Props {
  html: string
}

const WysiwygBlock = ({ html = '' }: Props) => {
  return <div className={styles.root} dangerouslySetInnerHTML={{ __html: html }} />
}

export default WysiwygBlock
