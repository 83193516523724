import styles from './TextBlock.module.scss'

interface Props {
  text: string
}

const TextBlock = ({ text = '' }: Props) => {
  return <div className={styles.root}>{text}</div>
}

export default TextBlock
