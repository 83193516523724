import { Button, Col, Container, Row } from 'react-bootstrap'
import useSWR from 'swr'
import { buildTravelFilterRequestVariables, TRAVEL_FILTER_QUERY } from '@/features/travelFilter/gql'
import TravelFilterResultList from '@/components/models/travel/filter/TravelFilterResultList'
import Link from 'next/link'
import { buildTravelFilterUrlQuery } from '@/features/travelFilter/helpers'
import { useTranslation } from 'next-i18next'
import ArrowIcon from '@/components/elements/icons/ArrowIcon'

interface Props {
  destinationIds?: number[]
  travelTypeIds?: number[]
  maxResults?: number
}

const PrefilteredTravelsBlock = ({ destinationIds = [], travelTypeIds = [], maxResults = 8 }: Props) => {
  const { t } = useTranslation('common')
  const filterSelections = {
    destinations: destinationIds,
    travelTypes: travelTypeIds,
  }
  const { data, error } = useSWR([TRAVEL_FILTER_QUERY, buildTravelFilterRequestVariables(filterSelections)], { revalidateOnFocus: false })
  if (error) return <></>
  if (!data) return <></>

  const queryString = buildTravelFilterUrlQuery(filterSelections)
  const url = `/tours${queryString ? `?${queryString}` : ''}`

  return (
    <>
      <TravelFilterResultList travels={data.travelFilter.travels.slice(0, maxResults)} immutable={true} />
      {data.travelFilter.travels.length > 0 && (
        <Container>
          <Row className={'justify-content-end mb-5'}>
            <Col xs={'auto'}>
              <Link href={url} passHref>
                <Button as={'a'} variant={'signal'} size={'lg'} className={'an-wobble-horizontal-trigger text-uppercase'}>
                  <ArrowIcon fontsize={'5rem'} className={'me-2 overflow-hidden'} />
                  {t('moreTravels')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default PrefilteredTravelsBlock
