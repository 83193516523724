import styles from './TravelFilterForm.module.scss'
import { SetTravelFilterSelectionPayload, TravelFilter, TravelFilterNameEnum, TravelFilterSelection } from '@/features/travelFilter/types'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Badge, Col, Form, Row } from 'react-bootstrap'
import { executeTravelFilterRequest, selectTravelFilter, setTravelFilterQuery, setTravelFilterSelection } from '@/features/travelFilter'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useTranslation } from 'next-i18next'
import { buildTravelFilterUrlQuery } from '@/features/travelFilter/helpers'
import FilterInput from '@/components/models/travel/filter/TravelFilterNav/FilterInput'
import dynamic from 'next/dynamic'
import { AppConfigContext } from '@/app/providers/AppConfigProvider'
import debounce from 'lodash.debounce'

const CheckboxFilterSelector = dynamic(() => import('@/components/models/travel/filter/TravelFilterNav/selectors/CheckboxFilterSelector'), {
  ssr: false,
})
const MonthFilterSelector = dynamic(() => import('@/components/models/travel/filter/TravelFilterNav/selectors/MonthFilterSelector'), { ssr: false })

interface Props {
  setUrlQueryString: (queryString: string) => void
  onChangeFilterSelection?: (queryString: string) => void
}

const calcInputColSize = (appConfig: any): number => {
  let inputCount = 0
  if (appConfig.travelFilter.inputs.destinations.enabled) {
    inputCount++
  }
  if (appConfig.travelFilter.inputs.months.enabled) {
    inputCount++
  }
  if (appConfig.travelFilter.inputs.travelTypes.enabled) {
    inputCount++
  }

  return inputCount ? Math.floor(12 / inputCount) : 12
}

const TravelFilterForm = ({ setUrlQueryString, onChangeFilterSelection = () => {} }: Props) => {
  const { t } = useTranslation('common')
  const appConfig = useContext(AppConfigContext)

  const dispatch = useAppDispatch()
  const filterState = useAppSelector(selectTravelFilter)

  const updateUrl = () => {
    setUrlQueryString(buildTravelFilterUrlQuery(filterState.filterSelections, filterState.query))
  }

  /** execute **/
  const applyFilter = () => {
    executeFilterQuery()
    setOpenFilter('')
    onChangeFilterSelection(buildTravelFilterUrlQuery(filterState.filterSelections, filterState.query))
  }

  const executeFilterQuery = () => {
    if (filterState.initialized) {
      updateUrl()
      dispatch(executeTravelFilterRequest({}))
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedExecuteFilterQuery = useCallback(
    debounce(() => executeFilterQuery(), 500),
    [filterState.query]
  )

  useEffect(() => {
    debouncedExecuteFilterQuery()
    // Cancel the debounced function in case it's still waiting to run when the component unmounts
    return debouncedExecuteFilterQuery.cancel
  }, [debouncedExecuteFilterQuery])

  useEffect(() => {
    updateUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.updateUrlTrigger])

  /** filter query **/
  const setFilterQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTravelFilterQuery({ query: event.target.value }))
  }

  /** filter inputs **/
  const [openedFilter, setOpenFilter] = useState('')

  const toggleFilter = (name: string) => {
    // execute query if any filter closing
    if (openedFilter) {
      executeFilterQuery()
    }

    // handle open state
    if (openedFilter === name) {
      setOpenFilter('')
    } else {
      setOpenFilter(name)
    }
  }

  const setFilterSelection = ({ name, selection }: SetTravelFilterSelectionPayload) => {
    dispatch(setTravelFilterSelection({ name: name, selection: selection }))
  }

  const getFilterInputLabel = (name: TravelFilterNameEnum) => {
    let badge = <></>
    if (filterState.filterSelections) {
      const selection = filterState.filterSelections[name]
      if (selection.length > 0) {
        badge = (
          <Badge pill className={styles.inputSelectedCountBadge}>
            {selection.length}
          </Badge>
        )
      }
    }
    return (
      <>
        {t('travelFilter.inputLabels.' + name)}
        {badge}
      </>
    )
  }

  const getFilterFromState = (name: TravelFilterNameEnum) => {
    return filterState.filters.find((filter) => {
      return filter.name === name
    }) as TravelFilter
  }

  const getFilterSelection = (name: TravelFilterNameEnum) => {
    return filterState.filterSelections ? filterState.filterSelections[name] : []
  }

  const inputColSize = calcInputColSize(appConfig)

  return (
    <>
      <Row className={styles.root}>
        {appConfig.travelFilter.inputs.destinations.enabled && (
          <FilterInput
            name={'destination'}
            label={getFilterInputLabel('destinations')}
            Selector={
              <CheckboxFilterSelector
                filter={getFilterFromState('destinations')}
                selection={getFilterSelection('destinations')}
                setSelection={(selection: TravelFilterSelection) => setFilterSelection({ name: 'destinations', selection: selection })}
                applySelection={applyFilter}
              />
            }
            setOpen={() => toggleFilter('destination')}
            isOpen={openedFilter === 'destination'}
            colSize={inputColSize}
          />
        )}
        {appConfig.travelFilter.inputs.months.enabled && (
          <FilterInput
            name={'months'}
            label={getFilterInputLabel('months')}
            Selector={
              <MonthFilterSelector
                filter={getFilterFromState('months')}
                selection={getFilterSelection('months')}
                setSelection={(selection: TravelFilterSelection) => setFilterSelection({ name: 'months', selection: selection })}
                applySelection={applyFilter}
              />
            }
            setOpen={() => toggleFilter('months')}
            isOpen={openedFilter === 'months'}
            colSize={inputColSize}
          />
        )}
        {appConfig.travelFilter.inputs.travelTypes.enabled && (
          <FilterInput
            name={'travelTypes'}
            label={getFilterInputLabel('travelTypes')}
            Selector={
              <CheckboxFilterSelector
                filter={getFilterFromState('travelTypes')}
                selection={getFilterSelection('travelTypes')}
                setSelection={(selection: TravelFilterSelection) => setFilterSelection({ name: 'travelTypes', selection: selection })}
                applySelection={applyFilter}
              />
            }
            setOpen={() => toggleFilter('travelType')}
            isOpen={openedFilter === 'travelType'}
            colSize={inputColSize}
          />
        )}
      </Row>
      {appConfig.travelFilter.inputs.query.enabled && (
        <Row className={styles.root}>
          <Form.Group as={Col} xs="12">
            {/*{isBrowser && (*/}
            <Form.Control
              type="text"
              name="query"
              placeholder={t('travelFilter.inputLabels.query')}
              className={styles.input}
              value={filterState.query}
              onChange={setFilterQuery}
            />
            {/*)}*/}
          </Form.Group>
        </Row>
      )}
    </>
  )
}

export default TravelFilterForm
