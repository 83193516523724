import styles from './TextWithIcon.module.scss'
import { Col, Row } from 'react-bootstrap'

interface TextWithIconProps {
  title?: string
  text: string
  Icon: JSX.Element
}

const TextWithIcon = ({ title = '', text = '', Icon }: TextWithIconProps) => {
  return (
    <div className={styles.root}>
      <Row>
        <Col xs="12" xl="auto" className={styles.iconWrap}>
          {Icon}
        </Col>
        <Col className={styles.content}>
          {title !== '' && <div className={styles.cardTitle}>{title}</div>}
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Col>
      </Row>
    </div>
  )
}

export default TextWithIcon
