import styles from './TravelGuidesBlock.module.scss'
import cmsTravelGuidesBlockObject from '@/entities/cmsTravelGuidesBlock/interface'
import travelGuideObject from '@/entities/travelGuide/interface'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TravelGuideTeaser from '@/components/models/travelGuide/TravelGuideTeaser'

const TravelGuidesBlock = ({ travelGuides }: cmsTravelGuidesBlockObject) => {
  return (
    <div className={`${styles.root}`}>
      <Row>
        {travelGuides.map((travelGuide: travelGuideObject, index: number) => {
          return (
            <Col lg={6} xxl={4} className={'pb-3'} key={index}>
              <TravelGuideTeaser travelGuide={travelGuide} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default TravelGuidesBlock
