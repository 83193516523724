import cmsIframeBlockObject from '@/entities/cmsIframeBlock/interface'
import IframeResizer from 'iframe-resizer-react'
import { Col } from 'react-bootstrap'

// Inject GET params to iFrame URL
const buildUrl = (url: string, params: { [x: string]: string }) => {
  return url + buildQueryString(params, url.includes('?'))
}

const buildQueryString = (params: { [x: string]: string }, urlHasParams: boolean) => {
  if (!Object.keys(params).length) {
    return ''
  }
  var str = []
  for (let p in params)
    if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
    }

  let connector = urlHasParams ? '&' : '?'
  return connector + str.join('&')
}

const IframeBlock = ({ url, minHeight }: cmsIframeBlockObject) => {
  if (!url) {
    return <></>
  }

  let params = {}
  if (typeof window !== 'undefined') {
    params = Object.fromEntries(new URL(window.location.href).searchParams)
  }

  return (
    <Col xs={12}>
      <IframeResizer
        src={buildUrl(url, params)}
        style={{ width: '1px', minWidth: '100%', minHeight: minHeight ? `${minHeight}px` : 'auto' }}
        heightCalculationMethod={'max'}
      />
    </Col>
  )
}

export default IframeBlock
