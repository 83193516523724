import CmsContentBlock from '@/components/models/cms/page/CmsContentBlock'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelTypeObject from '@/entities/travelType/interface'

interface Props {
  cmsPage: any
  travelTypes: travelTypeObject[]
  cmsConfig: cmsConfigObject
}

const CmsPage = ({ cmsPage, cmsConfig, travelTypes }: Props) => {
  return (
    <section id={'content'}>
      {cmsPage.contentBlockAssignments.map((assignment: any, index: number) => {
        return <CmsContentBlock type={assignment.type} block={assignment.block} cmsConfig={cmsConfig} travelTypes={travelTypes} key={index} />
      })}
    </section>
  )
}

export default CmsPage
