import { Container, Row, Col } from 'react-bootstrap'
import TravelResultItemTile from '@/components/models/travel/filter/TravelFilterResultList/TravelResultItemTile'
import { useAppSelector } from '@/app/hooks'
import { selectTravelFilter } from '@/features/travelFilter'
import { TravelFilterTravel } from '@/features/travelFilter/types'
import { useEffect, useState } from 'react'

interface Props {
  travels?: TravelFilterTravel[]
  immutable?: boolean
  className?: string
}

const TravelFilterResultList = ({ travels: initialTravels, immutable = false, className = '' }: Props) => {
  const filterState = useAppSelector(selectTravelFilter)
  const [travels, setTravels] = useState<TravelFilterTravel[]>(initialTravels ? initialTravels : filterState.travels)

  useEffect(() => {
    if (!immutable) {
      setTravels(filterState.travels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.travels])

  return (
    <Container className={className}>
      <Row>
        {travels.map((travel: any, index: number) => {
          return (
            <Col key={index} md={6} lg={4} xl={3} className={'mb-3'}>
              <TravelResultItemTile travel={travel} />
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default TravelFilterResultList
