import styles from './EmployeesBlock.module.scss'
import cmsEmployeesBlockObject from '@/entities/cmsEmployeesBlock/interface'
import employeeObject from '@/entities/employee/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { Card, Col, Row } from 'react-bootstrap'
import MailIcon from '@mui/icons-material/Mail'

const EmployeesBlock = ({ employees }: cmsEmployeesBlockObject) => {
  return (
    <div className={`${styles.root}`}>
      <Row>
        {employees.map((employee: employeeObject, index: number) => {
          return (
            <Col md={6} lg={4} className={'mb-3'} key={index}>
              <Card className={`h-100`}>
                <div className={'img-ratio-1-1'}>
                  <CloudinaryImage
                    image={employee.image}
                    aspectRatio={1}
                    width={424}
                    responsive={false}
                    altDefault={`${employee.firstName} ${employee.lastName}`}
                  />
                </div>
                <Card.Body className={styles.cardBody}>
                  <Card.Title className={styles.cardTitle}>
                    {employee.firstName} {employee.lastName}
                  </Card.Title>
                  <div className={styles.jobTitle}>{employee.jobTitle}</div>
                  <Card.Text as={'div'}>
                    <div className={styles.description}>{employee.description}</div>
                  </Card.Text>
                  {!!employee.email && (
                    <a href={`mailto:${employee.email}`} className={styles.contactInfo}>
                      <MailIcon fontSize="small" sx={{ mr: 1 }} />
                      <span>{employee.email}</span>
                    </a>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default EmployeesBlock
