import styles from './DestinationsBlock.module.scss'
import travelDestinationObject from '@/entities/travelDestination/interface'
import { useTranslation } from 'next-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { TitleH2 } from '@/components/cms/Title'
import DestinationAccordion from '@/components/models/destination/DestinationAccordion'

interface Props {
  destinations: travelDestinationObject[]
  collapsed?: boolean
}

const DestinationsBlock = ({ destinations, collapsed }: Props) => {
  const { t } = useTranslation('common')

  if (destinations.length) {
    return (
      <>
        <Container className={styles.root}>
          <span id="destinations" className="anchor" />
          <Row>
            <Col>
              <TitleH2 title={t('travel.countryInformation')} />
              <DestinationAccordion destinations={destinations} collapsed={collapsed} />
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  return <Container className={styles.root} />
}

export default DestinationsBlock
