import cmsDividerBlockObject from '@/entities/cmsDividerBlock/interface'
import ContentDivider from '@/components/elements/ContentDivider'

const DividerBlock = ({}: cmsDividerBlockObject) => {
  return (
    <>
      <ContentDivider className={'mt-4 mb-5'} />
    </>
  )
}

export default DividerBlock
