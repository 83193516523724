import styles from './IssuuCatalogBlock.module.scss'
import cmsIssuuCatalogBlockObject from '@/entities/cmsIssuuCatalogBlock/interface'

const IssuuCatalogBlock = ({ publicationCode, accountName }: cmsIssuuCatalogBlockObject) => {
  return (
    <div className={styles.root}>
      <iframe
        title={'Issuu Publication'}
        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
        allowFullScreen={true}
        src={`https://e.issuu.com/embed.html?d=${publicationCode}&u=${accountName}`}
      />
    </div>
  )
}

export default IssuuCatalogBlock
