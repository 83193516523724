import styles from '@/components/models/travel/filter/FilterLeadForm/FilterLeadFormSubmitButton/FilterLeadFormSubmitButton.module.scss'
import { useTranslation } from 'next-i18next'

const FilterLeadFormSubmitButton = () => {
  const { t } = useTranslation('common')

  return <div className={`an-forward ${styles.root}`}>{t('travelFilter.button.search')}</div>
}

export default FilterLeadFormSubmitButton
