import styles from './TravelTypesBlock.module.scss'
import { useTranslation } from 'next-i18next'
import travelTypeObject from '@/entities/travelType/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CardButton from '@/components/elements/buttons/CardButton'

interface Props {
  travelTypes: travelTypeObject[]
}
const TravelTypesBlock = ({ travelTypes }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Container className={`${styles.root} mb-5`}>
      {travelTypes.map((travelType, index: number) => {
        const imagePosition = index % 2 === 0 ? 'left' : 'right'
        const imageColOptions = imagePosition === 'left' ? { span: 6 } : { span: 6, order: 2 }
        return (
          <Card className={styles.card + ` ${imagePosition === 'left' ? styles.left : styles.right}`} key={index}>
            <Row className="gx-0">
              <Col lg={imageColOptions}>
                <div className={`box-ratio-16-9`}>
                  <div className={`box-ratio-inner ${styles.imgWrap}`}>
                    <CloudinaryImage image={travelType.image} width={704} aspectRatio={1.777} responsive={false} className={'card-img d-lg-none'} />
                    <CloudinaryImage
                      image={travelType.image}
                      width={704}
                      aspectRatio={1.4}
                      responsive={false}
                      className={'card-img d-none d-xl-block'}
                    />
                    <CloudinaryImage
                      image={travelType.image}
                      width={704}
                      aspectRatio={1.1}
                      responsive={false}
                      className={'card-img d-none d-lg-block d-xl-none'}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <Card.Body className={`${styles.body}`}>
                  <Card.Title>{travelType.name}</Card.Title>
                  <Card.Text as={'div'} className={styles.cardText}>
                    {travelType.description}
                    <CardButton label={t('travel.showTravels')} href={`/tours?travelTypes=${travelType.id}`} className={styles.ribbon} />
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        )
      })}
    </Container>
  )
}

export default TravelTypesBlock
