// import styles from './GridBlock.module.scss'
import { Col, Row } from 'react-bootstrap'
import CmsContentBlock from '@/components/models/cms/page/CmsContentBlock'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelTypeObject from '@/entities/travelType/interface'

interface Props {
  data: any
  cmsConfig: cmsConfigObject
  travelTypes: travelTypeObject[]
}

const GridBlock = ({ data, cmsConfig, travelTypes }: Props) => {
  return (
    <Row>
      {data.columns.map((column: any, index: number) => {
        return (
          <Col key={index} xs={12} lg={6}>
            {column.contentBlockAssignments.map((assignment: any, index: number) => {
              return (
                <CmsContentBlock
                  type={assignment.type}
                  block={assignment.block}
                  cmsConfig={cmsConfig}
                  withGridWrap={false}
                  travelTypes={travelTypes}
                  key={index}
                />
              )
            })}
          </Col>
        )
      })}
    </Row>
  )
}

export default GridBlock
