import cmsNewsletterRegisterBlockObject from '@/entities/cmsNewsletterRegisterBlock/interface'
import { Col, Container, Row } from 'react-bootstrap'
import NewsletterForm from '@/components/models/newsletter/NewsletterForm'

const NewsletterRegistrationBlock = ({ fluid }: cmsNewsletterRegisterBlockObject) => {
  return (
    <Container fluid className={`bg-tertiary an-wobble-horizontal-trigger`}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={9} xl={7} className="mt-5 mb-5 mb-md-2">
            <NewsletterForm />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default NewsletterRegistrationBlock
