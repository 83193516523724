import styles from './PageHeaderBlock.module.scss'
import cmsPageHeaderBlockObject from '@/entities/cmsPageHeaderBlock/interface'
import { Col, Container, Row } from 'react-bootstrap'
import ResponsiveHeaderImage from '@/components/elements/media/ResponsiveHeaderImage'

const PageHeaderBlock = ({ image, title, text }: cmsPageHeaderBlockObject) => {
  return (
    <>
      <Container fluid className={styles.root}>
        <Row className="h-100">
          <Col className="p-0">
            <ResponsiveHeaderImage image={image} />
          </Col>
          <div className={styles.content}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            <Col sm={12} className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </Row>
      </Container>
      <style global jsx>
        {`
          main {
            margin-top: 0;
          }
        `}
      </style>
    </>
  )
}

export default PageHeaderBlock
