import styles from '@/components/models/cms/blocks/FilterLeadFormBlock/FilterLeadFormBlock.module.scss'
import cmsFilterLeadBlockObject from '@/entities/cmsFilterLeadBlock/interface'
import { Col, Container, Row } from 'react-bootstrap'
import FilterLeadForm from '@/components/models/travel/filter/FilterLeadForm'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { initializeTravelFilter, selectTravelFilter } from '@/features/travelFilter'
import { useEffect } from 'react'

const FilterLeadFormBlock = ({ layout }: cmsFilterLeadBlockObject) => {
  const dispatch = useAppDispatch()
  const filterState = useAppSelector(selectTravelFilter)

  useEffect(() => {
    if (!filterState.initialized) {
      dispatch(initializeTravelFilter({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container fluid className={`${styles.root} shadow-lg`}>
      <Container>
        <Row>
          <Col>
            <FilterLeadForm />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default FilterLeadFormBlock
