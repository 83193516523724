import styles from './FilterLeadForm.module.scss'
import { Col, Row } from 'react-bootstrap'
import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import TravelFilterForm from '@/components/models/travel/filter/TravelFilterForm'
import TravelFilterInfoAndReset from '@/components/models/travel/filter/TravelFilterInfoAndReset'
import FilterLeadFormSubmitButton from '@/components/models/travel/filter/FilterLeadForm/FilterLeadFormSubmitButton'
import { useRouter } from 'next/router'

const FilterLeadForm = () => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const [queryString, setQueryString] = useState<string>('')

  const setUrlQueryString = (queryString: string) => {
    setQueryString(queryString ? `?${queryString}` : '')
  }

  const handleChangeFilterSelection = (queryString: string) => {
    router.push(`/tours?${queryString}`)
  }

  return (
    <div id="travel-filter-lead-form" className={styles.root}>
      <Row>
        <Col lg="3" className={`${styles.title}`}>
          {t('travelFilter.findTravel')}:
        </Col>
        <Col lg="6" className={styles.inputs}>
          <TravelFilterForm setUrlQueryString={setUrlQueryString} onChangeFilterSelection={handleChangeFilterSelection} />
        </Col>
        <Col lg="3">
          <Row className={styles.submitWrap}>
            <Col xs="auto">
              <Link href={`/tours${queryString}`} passHref>
                <a>
                  <FilterLeadFormSubmitButton />
                </a>
              </Link>
            </Col>
            <Col className={`align-self-end mt-2 mt-lg-0 ${styles.resultInfoWrap}`}>
              <TravelFilterInfoAndReset />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default FilterLeadForm
