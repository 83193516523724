import Accordion from '@/components/elements/Accordion'
import DestinationInformation from '@/components/models/destination/DestinationInformation'
import travelDestinationObject from '@/entities/travelDestination/interface'

interface Props {
  destinations: Array<travelDestinationObject>
  collapsed?: boolean
}

const DestinationAccordion = ({ destinations, collapsed }: Props) => {
  const accordionItems = destinations.map((destination: travelDestinationObject) => {
    return {
      title: destination.name,
      content: <DestinationInformation destination={destination} />,
    }
  })
  return (
    <>
      <Accordion items={accordionItems} collapsed={collapsed} />
    </>
  )
}

export default DestinationAccordion
